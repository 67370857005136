@import '../../styles/propertySets.css';

.root {
}

.heading {
  @apply --marketplaceModalTitleStyles;
}

.radioButtonRow {
  margin: 25px 0 50px;
  
  @media (--viewportMedium) {
    margin: 25px 0 100px;
  }
}

.error {
  color: var(--failColor);
  margin-top: 0;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}
