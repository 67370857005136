@import '../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: column;
}

.bookingDatesStart,
.bookingDatesEnd {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  margin-bottom: 38px;

  /* Ensure that calendar dropdown gets some stacking context relative to other form items below */
  z-index: 5;
}

.bookingDatesStart {
  z-index: 6;
}

.priceBreakdownContainer {
  padding: 0 24px 100px;
  margin-bottom: 40px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
    margin-top: 1px;
  }
}

.priceBreakdownTitle {
  /* Font */
  color: var(--matterColorAnti);

  margin-top: 5px;
  margin-bottom: 14px;
  @media (--viewportMedium) {
    margin-top: 5px;
    margin-bottom: 26px;
  }
}

.receipt {
  flex-shrink: 0;
  margin: 0 0 24px 0;
}

.error {
  color: var(--failColor);
  margin: 0 24px;
  display: inline-block;
}

.spinner {
  margin: auto;
}

.sideBarError {
  @apply --marketplaceH4FontStyles;
  color: var(--failColor);
  margin: 0 24px 12px 24px;

  @media (--viewportMedium) {
    margin: 0 0 12px 0;
  }
}

.sideBarErrorMargin {
  margin-top: 30px;
}

.smallPrint {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  text-align: center;
  margin: 20px 24px 20px 24px;
  flex-shrink: 0;

  @media (--viewportMedium) {
    margin-top: auto;
    margin-bottom: 20px;
  }

  @media (--viewportLarge) {
    margin-top: 10px;
    margin-bottom: 21px;
  }
}

.submitButtonWrapper {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%;
  margin-bottom: 300px;

  @media (--viewportMedium) {
    padding: 0;
    width: 100%;
    margin-bottom: 0;
  }
}

.units {
  font-size: 12px;
  margin-bottom: 30px;
  color: var(--marketplaceColor);
  margin-top: -40px;

  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.quantity {
  margin-bottom: 50px;
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 30px;
  }
}

.bookingDatesStart {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 55;
}

.bookingEndDates {
  display: flex;
}
