@import '../../styles/propertySets.css';

.root {
  & p {
    @apply --marketplaceH4FontStyles;
      font-weight: 400;
  }
  & h2 {
    /* Adjust heading margins to work with the reduced body font size */
    margin: 29px 0 13px 0;

    @media (--viewportMedium) {
      margin: 32px 0 0 0;
    }
  }

  & ul {
      margin-bottom: 30px;
      font-weight: 400;
      padding-left: 20px;
      list-style: disc;
      
      @media (--viewportMedium) {
        padding-left: 40px;
      }

    & li {
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
      }
  }

  & .lastUpdated {
    @apply --marketplaceBodyFontStyles;
    margin-top: 0;
    margin-bottom: 55px;

    @media (--viewportMedium) {
      margin-top: 0;
      margin-bottom: 54px;
    }
  }
}

.italic {
  font-style: italic;
}

.bold {
  font-weight: 600;
}
