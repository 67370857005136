.root {
  display: flex;
  flex-direction: column;
}

.listingCards {
  display: inline-grid;
  width: 100%;
  grid-template-columns: 94%;
  grid-gap: 2vh;
  padding: 0 0 96px 36px !important;

  @media (min-width: 620px) {
    display: inline-grid;
    width: 100%;
    grid-template-columns: repeat(2, calc(94% / 2));
    padding: 0 24px 96px 24px;
    padding: 0 36px 96px 36px;
  }

  @media (min-width: 920px) {
    display: inline-grid;
    width: 100%;
    grid-template-columns: repeat(3, calc(94% / 3));
    padding: 0 24px 96px 24px;
    padding: 0 36px 96px 36px;
  }

  @media (min-width: 1280px) {
    display: inline-grid;
    width: 100%;
    grid-template-columns: repeat(3, calc(94% / 3));
    padding: 0 24px 96px 24px;
    padding: 0 36px 96px 36px;
  }

  @media (min-width: 1670px) {
    display: inline-grid;
    width: 100%;
    grid-template-columns: repeat(4, calc(94% / 4));
    padding: 0 24px 96px 24px;
    padding: 0 36px 96px 36px;
  }
}

.listingCard {
  width: auto;
}

/**
 * Remove margin-right from listingCards on the last column
 * This is a special case in the world of breakpoints. Nth-of-type effects every nth item.
 * Here we take margin-right away, but we don't want it affect the next column breakpoint.
 */

.listingCard:nth-of-type(2n) {
  @media screen and (min-width: 1024px) and (max-width: 1920px) {
    margin-right: 0;
  }
}

.listingCard:nth-of-type(3n) {
  @media (--viewportXLarge) {
    margin-right: 0;
  }
}

.pagination {
  /* margin-top: auto; pushes pagination to the end of the page. */
  margin-top: auto;
  /* background-color: var(--matterColorLight); */
  padding: 0 24px;

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}
