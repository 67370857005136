@import '../../styles/propertySets.css';

.root {

  & nav ul li {
    width: 100%;
    margin-bottom: 10px;
  }

  & nav ul li a {
    font-weight: var(--fontWeightRegular);
    color: var(--matterColor);
    font-size: 16px;

    &:hover,
    &:focus {
      color: var(--marketplaceColor);
    }
  }

  & ol {
    list-style: none;
    padding-left: 60px;
  }

  & ol li {
    position: relative;
    font-weight: var(--fontWeightRegular);
    margin-bottom: 25px;
    font-size: 16px;
    line-height: 24px;
  }

  & ol li span {
    position: absolute;
    left: -40px;
    top: 0;
  }

  & ol li ol {
    margin: 20px 0;
  }

  & ol li ol li span {
    left: -45px;
  }

  & p {
    @apply --marketplaceH4FontStyles;
    font-weight: 400;
  }
  & h2 {
    /* Adjust heading margins to work with the reduced body font size */
    margin: 29px 0 13px 0;

    @media (--viewportMedium) {
      margin: 32px 0 0 0;
    }
  }

  & .lastUpdated {
    @apply --marketplaceBodyFontStyles;
    margin-top: 0;
    margin-bottom: 55px;

    @media (--viewportMedium) {
      margin-top: 0;
      margin-bottom: 54px;
    }
  }

  & .anchor {
    position: static;
  }
}

.bold {
  font-weight: 600;
}
