@import '../../styles/propertySets.css';

.root {
  @apply --marketplaceModalFormRootStyles;

  justify-content: flex-start;

  /* @media (--viewportMedium) {
    justify-content: space-between;
  } */

  & button {
    min-height: 48px;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  /* margin-top: 24px; */
  margin-top: 16px;

  /* @media (--viewportMedium) {
    margin-top: 32px;
  } */
}

.firstNameRoot {
  width: calc(34% - 9px);
}

.lastNameRoot {
  width: calc(66% - 9px);
}

.password {
  @apply --marketplaceModalPasswordMargins;
}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;
  margin-top: 38px;
  
  @media (--viewportMedium) {
    margin-top: 30px;
  }
}

.bottomWrapperText {
  @apply --marketplaceModalBottomWrapperText;
  margin-bottom: 8px;
}

.termsText {
  @apply --marketplaceModalHelperText;
}

.termsLink {
  @apply --marketplaceModalHelperLink;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
