
.root {
  display: flex;
  width: 30px;
  height: 30px;
  margin-left: 20px;
  flex-shrink: 0;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.icon {
  width: 100%;
  height: 100%;
  /* fill: var(--marketplaceColor); */
  text-align: center;
}
