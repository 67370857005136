@import '../../styles/propertySets.css';

.root {
  position: relative;
  display: block;
  margin-left: 0px;
  max-width: 120px;
  margin-bottom: 30px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.languageButton {
  text-transform: uppercase;
  padding: 0;
  margin: 15px 0 20px;

  @media (--viewportMedium) {
    padding: 0 25px 0 8px;
    margin: 0;
  }

  & .languageOption {
    padding: 0;

    @media (--viewportMedium) {
      padding: 16px 0 10px;
    }
  }
}

.languagesOptions {
  max-width: 95px;
  top: 42px;
  left: 0;

  @media (--viewportMedium) {
    top: auto;
    left: 50%;
    transform: translateX(-50%);
  }

  & > div {
    position: absolute;
    right: 50%;
  }
}

.languageOption {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  font-size: 16px;
  line-height: 1;
  font-weight: var(--fontWeightSemiBold);
}

.flagImg {
  width: 30px;
  height: auto;
  flex-shrink: 0;
  margin-right: 10px;
  box-shadow: var(--boxShadowButton);
}
