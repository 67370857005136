.root {
}

.input {
  border-bottom-color: var(--attentionColor);
  max-width: 90px;
  padding: 0 20px;
  margin:  0 20px;
  text-align: center;
}

input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.inputSuccess {
  border-bottom-color: var(--successColor);
}

.inputError {
  border-bottom-color: var(--failColor);
}

.quantityLabel {
  margin-bottom: 6px;
}

.quantityWrapper {
  display: flex;
}

.quantityButton {
  width: 30px;
  height: 30px;
  flex: 0 0 30px;
  background: var(--attentionColor);
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
}

.quantityButtonBlocked {
  opacity: 0.5;
  pointer-events: none;
}
