@import '../../styles/propertySets.css';

.root {
    display: flex;
    flex-direction: column;
}

.submitButton {
    margin-top: 22px;

    @media (--viewportMedium) {
      margin-top: 26px;
    }
    @media (--viewportLarge) {
      margin-top: 17px;
    }
}

.messageHeading {
    color: var(--matterColorAnti);
    margin: 36px 0 12px 0;

    padding-top: 4px;
    padding-bottom: 2px;

    @media (--viewportMedium) {
      margin: 24px 0 24px 0;
      padding-top: 4px;
      padding-bottom: 4px;
    }
}

.message {
    border-bottom-color: var(--matterColorAnti);
}
